import type { Stores, Prefectures, City } from '~/schema'
interface Area {
  id: number
  name: string
  count: number
  prefectures: Prefecture[]
}
interface Prefecture {
  id: number
  name: string
  count: number
  cities: City[]
}

export const usePrefectures = async (
  storesData: Ref<Stores['data'] | null>,
  prefecturesData: Ref<Prefectures['data'] | null>,
) => {
  const { t } = useI18n()

  const prefectures = computed(
    () => prefecturesData.value?.map((prefecture) => prefecture) ?? [],
  )
  const areas = computed<Area[]>(() => {
    if (!storesData.value) return []
    const nonExistentNumber = 0
    return [
      {
        id: 0,
        name: t('area.hokkaidoTohoku'),
        count: storesData.value.filter((store) =>
          [...Array(7)]
            .map((_, i) => i + 1)
            .includes(
              store.address ? store.address.prefecture : nonExistentNumber,
            ),
        ).length,
        prefectures: prefectures.value.slice(0, 7).map((prefecture) => ({
          ...prefecture,
          count:
            storesData.value?.filter(
              (store) => store.address?.prefecture === prefecture.id,
            ).length ?? 0,
        })),
      },
      {
        id: 1,
        name: t('area.kanto'),
        count: storesData.value.filter((store) =>
          [...Array(7)]
            .map((_, i) => i + 8)
            .includes(
              store.address ? store.address.prefecture : nonExistentNumber,
            ),
        ).length,
        prefectures: prefectures.value.slice(7, 14).map((prefecture) => ({
          ...prefecture,
          count:
            storesData.value?.filter(
              (store) => store.address?.prefecture === prefecture.id,
            ).length ?? 0,
        })),
      },
      {
        id: 2,
        name: t('area.hokurikuKoshinetsu'),
        count: storesData.value.filter((store) =>
          [...Array(6)]
            .map((_, i) => i + 15)
            .includes(
              store.address ? store.address.prefecture : nonExistentNumber,
            ),
        ).length,
        prefectures: prefectures.value.slice(14, 20).map((prefecture) => ({
          ...prefecture,
          count:
            storesData.value?.filter(
              (store) => store.address?.prefecture === prefecture.id,
            ).length ?? 0,
        })),
      },
      {
        id: 3,
        name: t('area.tokai'),
        count: storesData.value.filter((store) =>
          [...Array(4)]
            .map((_, i) => i + 21)
            .includes(
              store.address ? store.address.prefecture : nonExistentNumber,
            ),
        ).length,
        prefectures: prefectures.value.slice(20, 24).map((prefecture) => ({
          ...prefecture,
          count:
            storesData.value?.filter(
              (store) => store.address?.prefecture === prefecture.id,
            ).length ?? 0,
        })),
      },
      {
        id: 4,
        name: t('area.kansai'),
        count: storesData.value.filter((store) =>
          [...Array(6)]
            .map((_, i) => i + 25)
            .includes(
              store.address ? store.address.prefecture : nonExistentNumber,
            ),
        ).length,
        prefectures: prefectures.value.slice(24, 30).map((prefecture) => ({
          ...prefecture,
          count:
            storesData.value?.filter(
              (store) => store.address?.prefecture === prefecture.id,
            ).length ?? 0,
        })),
      },
      {
        id: 5,
        name: t('area.chugoku'),
        count: storesData.value.filter((store) =>
          [...Array(5)]
            .map((_, i) => i + 31)
            .includes(
              store.address ? store.address.prefecture : nonExistentNumber,
            ),
        ).length,
        prefectures: prefectures.value.slice(30, 35).map((prefecture) => ({
          ...prefecture,
          count:
            storesData.value?.filter(
              (store) => store.address?.prefecture === prefecture.id,
            ).length ?? 0,
        })),
      },
      {
        id: 6,
        name: t('area.shikoku'),
        count: storesData.value.filter((store) =>
          [...Array(4)]
            .map((_, i) => i + 36)
            .includes(
              store.address ? store.address.prefecture : nonExistentNumber,
            ),
        ).length,
        prefectures: prefectures.value.slice(35, 39).map((prefecture) => ({
          ...prefecture,
          count:
            storesData.value?.filter(
              (store) => store.address?.prefecture === prefecture.id,
            ).length ?? 0,
        })),
      },
      {
        id: 7,
        name: t('area.kyushuOkinawa'),
        count: storesData.value.filter((store) =>
          [...Array(8)]
            .map((_, i) => i + 40)
            .includes(
              store.address ? store.address.prefecture : nonExistentNumber,
            ),
        ).length,
        prefectures: prefectures.value.slice(39, 47).map((prefecture) => ({
          ...prefecture,
          count:
            storesData.value?.filter(
              (store) => store.address?.prefecture === prefecture.id,
            ).length ?? 0,
        })),
      },
    ]
  })

  return { areas }
}
